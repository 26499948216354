import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import VideoGallery from "@components/videoGallery"
import { Heading2, sectionMd } from "@styles/ui"

const EducationalWebinars = ({ data, location }) => {
  const VideoFootnote = (
    <span tw="block mt-4 text-[12px] leading-4 md:(text-xs leading-[19px])">
      Healthcare professionals and real XYWAV patient were compensated for their
      time.
    </span>
  )

  const videoGallery = [
    {
      wistiaId: "okwizbfyyb",
      title: "Intro to XYWAV",
      description: (
        <>
          Learn about narcolepsy and also XYWAV, which may help treat cataplexy
          and/or EDS in people with narcolepsy. Plus, learn about the sodium
          content of XYWAV and why that matters for people with narcolepsy.
          Please see Important Safety Information and full Prescribing
          Information, including BOXED Warning, and Medication Guide above.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb1,
      thumbnailSmall: data.vidThumb1Small,
      alt: "Watch video: Intro to XYWAV",
      id: 0,
      metadata: {
        name: "Intro to XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about narcolepsy and XYWAV®, a treatment option for cataplexy and/or EDS in patients with narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb1.publicURL,
        uploadDate: "2023-01-08",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=okwizbfyyb",
        duration: "PT33M42S",
      },
    },
    {
      wistiaId: "ceub6tmnxb",
      title: "Getting XYWAV",
      description: (
        <>
          Learn about the steps you can take to get XYWAV, whether you have
          never taken XYREM<sup>&reg;</sup> (sodium oxybate) oral solution, or
          you are currently taking XYREM. Please see Important Safety
          Information and full Prescribing Information, including BOXED Warning,
          and Medication Guide above.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb2,
      thumbnailSmall: data.vidThumb2Small,
      alt: "Watch video: Getting XYWAV",
      id: 1,
      metadata: {
        name: "Getting XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn about the steps you can take to get XYWAV, whether you have never taken XYREM® (sodium oxybate) oral solution, or you are currently taking XYREM. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb2.publicURL,
        uploadDate: "2023-01-08",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=ceub6tmnxb",
        duration: "PT15M48S",
      },
    },
    {
      wistiaId: "qglqwbbe42",
      title: (
        <>
          First Few Weeks
          <br />
          on XYWAV
        </>
      ),
      description: (
        <>
          Learn what you may expect during the first few weeks of XYWAV
          treatment for narcolepsy. Please see Important Safety Information and
          full Prescribing Information, including BOXED Warning, and Medication
          Guide above.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb3,
      thumbnailSmall: data.vidThumb3Small,
      alt: "Watch video: First few weeks on XYWAV",
      id: 2,
      metadata: {
        name: "First Few Weeks on XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Learn what you may expect during the first few weeks of XYWAV treatment for narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb3.publicURL,
        uploadDate: "2023-01-08",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=qglqwbbe42",
        duration: "PT25M29S",
      },
    },
    {
      wistiaId: "k9mrr6wab8",
      title: (
        <>
          The History and
          <br tw="hidden lg:inline" /> Use of Oxybates
          <br tw="hidden lg:inline" /> in Narcolepsy
        </>
      ),
      description: (
        <>
          Discover the history of oxybates used to treat cataplexy and excessive
          daytime sleepiness in narcolepsy, including XYWAV. Please see
          Important Safety Information and full Prescribing Information,
          including BOXED Warning, and Medication Guide above.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb4,
      thumbnailSmall: data.vidThumb4Small,
      alt: "Watch video: The History and Use of Oxybates in Narcolepsy",
      id: 3,
      metadata: {
        name: "The History and Use of Oxybates in Narcolepsy | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Discover the history of oxybates used to treat cataplexy and excessive daytime sleepiness in narcolepsy, including XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=k9mrr6wab8",
        duration: "PT13M10S",
      },
    },
    {
      wistiaId: "7ps5ehy8cd",
      title: <>Understanding Differences in Narcolepsy Symptoms</>,
      description: (
        <>
          Find out about the different ways in which people experience cataplexy
          and excessive daytime sleepiness, 2 of the hallmark symptoms of
          narcolepsy and learn how XYWAV may be able to help with those
          symptoms. Please see Important Safety Information and full Prescribing
          Information, including BOXED Warning, and Medication Guide above.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb5,
      thumbnailSmall: data.vidThumb5Small,
      alt: "Watch video: Understanding Differences in Narcolepsy Symptoms",
      id: 4,
      metadata: {
        name: "Understanding Differences in Narcolepsy Symptoms | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Discover the history of oxybates used to treat cataplexy and excessive daytime sleepiness in narcolepsy, including XYWAV. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb4.publicURL,
        uploadDate: "2023-05-15",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=7ps5ehy8cd",
        duration: "PT21M57S",
      },
    },
    {
      wistiaId: "usivi8atz8",
      title: (
        <>
          A Patient&apos;s
          <br />
          Perspective on
          <br />
          JazzCares<sup>&reg;</sup>
        </>
      ),
      description: (
        <>
          Meet Brooke, a real XYWAV patient, and Cyndy, a JazzCares Nurse Case
          Manager, as they discuss the support offerings available to patients
          on XYWAV through JazzCares.
          {VideoFootnote}
        </>
      ),
      thumbnail: data.vidThumb6,
      thumbnailSmall: data.vidThumb6Small,
      alt: "Watch video: A Patient's Perspective on JazzCares®",
      id: 5,
      metadata: {
        name: "A Patient's Perspective on JazzCares® | XYWAV® (calcium, magnesium, potassium, and sodium oxybates)",
        description:
          "Meet Brooke, a real XYWAV patient, and Cyndy, a JazzCares Nurse Case Manager, as they discuss the support offerings available to patients on XYWAV through JazzCares. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse.",
        thumbnailUrl: data.vidThumb5.publicURL,
        uploadDate: "2023-11-13",
        contentUrl:
          "https://www.xywav.com/narcolepsy/educational-webinars/?wvideo=usivi8atz8",
        duration: "PT21M20S",
      },
    },
  ]
  return (
    <Layout
      location={location}
      isiBgColour="white"
      footerBgColour="white"
      activeNavIndex={3}
      exitModalColour="red"
    >
      <Seo
        title="Narcolepsy Educational Webinars | XYWAV®"
        description="Watch educational webinars presented by healthcare professionals who specialize in narcolepsy and XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral solution for cataplexy and/or EDS. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
        videoMetadata={videoGallery}
      />
      <StaticHero eyebrow="Educational Webinars" gradient="red">
        <>
          Learn about XYWAV and narcolepsy from the <br tw="hidden lg:block" />
          experts
        </>
      </StaticHero>

      <section css={sectionMd}>
        <Container>
          <h2
            css={[
              Heading2,
              tw`mb-6 pt-4 text-center lg:(mb-5 pt-6)`,
              tw`before:(block rounded-md relative left-[calc(50% - 50px)] bottom-4 w-[100px] h-1.5 gradientRed lg:(w-[68px] left-[calc(50% - 34px)] bottom-6))`,
            ]}
          >
            Watch the XYWAV Educational Webinars
          </h2>
          <div
            className="xw-two-videos-with-text__subheading"
            tw="relative text-center mb-12"
          >
            <p tw="mb-4 lg:mb-2.5">
              This series of webinars features healthcare professionals who are
              experts in narcolepsy and patients who are taking XYWAV treatment.{" "}
              <br tw="hidden lg:block" />
              These sessions will give you valuable information about
              narcolepsy, how to get XYWAV, how XYWAV may treat your cataplexy
              and/or excessive daytime sleepiness (EDS), and much more.
            </p>
            <p tw="mb-4 lg:mb-2.5">
              Watch this page for updates as new webinars are added.
            </p>
          </div>

          {/* Video Gallery */}
          <VideoGallery
            videos={videoGallery}
            location={location}
            sectionId="webinars"
            theme="narcolepsy"
          />
        </Container>
      </section>
    </Layout>
  )
}

export default EducationalWebinars

export const query = graphql`
  query {
    vidThumb1: file(
      relativePath: { eq: "educational-webinars/intro-video-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb1Small: file(
      relativePath: { eq: "educational-webinars/intro-video-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb2: file(
      relativePath: { eq: "educational-webinars/getting-video-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb2Small: file(
      relativePath: { eq: "educational-webinars/getting-video-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb3: file(
      relativePath: { eq: "educational-webinars/first-weeks-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb3Small: file(
      relativePath: { eq: "educational-webinars/first-weeks-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb4: file(
      relativePath: { eq: "educational-webinars/history-thumbnail.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb4Small: file(
      relativePath: { eq: "educational-webinars/history-thumbnail.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb5: file(
      relativePath: {
        eq: "educational-webinars/narcolepsy-differences-thumbnail.png"
      }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb5Small: file(
      relativePath: {
        eq: "educational-webinars/narcolepsy-differences-thumbnail.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidThumb6: file(
      relativePath: { eq: "educational-webinars/patient-perspective.png" }
    ) {
      publicURL
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidThumb6Small: file(
      relativePath: { eq: "educational-webinars/patient-perspective.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
  }
`
